<!-- 个人中心-收货地址组件 -->
<template>
  <div class="addressdataset">
    <usercentertitle class='usercentertitle' :types='2' title='新增收货地址'></usercentertitle>
    <div class="group-adr">
      <div class="lt">
        <span class="xx">*</span>
        收货人：
      </div>
      <div class="rt">
        <div class="ipt">
          <input type="text" placeholder='长度不超过25个字符' v-model='name'>
        </div>
        <span class="err" v-show='errormsg.name'>{{errormsg.name}}</span>
      </div>
    </div>
    <div class="group-adr">
      <div class="lt">
        <span class="xx">*</span>
        详细地址：
      </div>
      <div class="rt">
        <div class="slt">
          <el-select  v-model="optionsObj.value" placeholder="省份" @change='change'>
           <el-option v-for="item,index in optionsObj.options" :key='index' :label='item.label' :value='item.value' ></el-option>
          </el-select>
          <el-select  v-model="optionsObj1.value" placeholder="城市" @change='change1'>
            <el-option  v-for="item,index in optionsObj1.options" :key='index' :label='item.label' :value='item.value' ></el-option>
          </el-select>
          <el-select  v-model="optionsObj2.value" placeholder="区县" @change='change2'>
            <el-option v-for="item,index in optionsObj2.options" :key='index' :label='item.label' :value='item.value' ></el-option>
          </el-select>
        </div>
        <div class="area">
          <textarea placeholder='请输入详细地址信息，如街道、小区、门牌号等' v-model='addredetail'></textarea>
        </div>
        <span class="err errs" v-show='errormsg.addredetail'>{{errormsg.addredetail}}</span>
      </div>
    </div>
    <div class="group-adr">
      <div class="lt">
        <span class="xx">*</span>
        联系手机：
      </div>
      <div class="rt">
        <div class="ipt ipts">
          <el-select v-model="value3">
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <input type="text" class="txt" placeholder="请填写手机号" v-model='phone'>
        </div>
        <span class="err" v-show='errormsg.phone'>{{errormsg.phone}}</span>
      </div>
    </div>
    <div class="group-adr">
      <div class="lt">
        邮政编码：
      </div>
      <div class="rt">
        <div class="ipt">
          <input type="text" placeholder='请填写邮编' v-model='PostCode'>
        </div>
        <span class="err" v-show='errormsg.PostCode'>{{errormsg.PostCode}}</span>
      </div>
    </div>
    <div class="group-adr">
      <div class="lt">
        固定电话：
      </div>
      <div class="rt">
        <div class="ipt">
          <input type="text" v-model='telphone' placeholder='请填写固定电话'>
        </div>
        <span class="err" v-show='errormsg.telphone'>{{errormsg.telphone}}</span>
      </div>
    </div>
    <div class="discern">
      <textarea placeholder="按格式粘贴文本，可自动识别姓名，地址，电话                          如：张三，广东省深圳市宝安区宝安大道塘尾地铁C出口标帆科技大厦，13888888888" v-model='discernData'></textarea>
      <button @click="discernBtn"><span></span>点击识别地址</button>
    </div>
    <div class="dftadr" @click='setdft'>
      <span><svg v-show='setdfts' class="icon"><use xlink:href="#iconwancheng"></use></svg></span>设为默认收货地址
    </div>
    <div class="savebtn">
      <button @click='saveinfo'>保存</button>
    </div>
  </div>
</template>
<script>
import usercentertitle from '@/components/usercenter-title/usercenter-title.vue';//内容标题
export default {
  name: 'addressdataset',
  components: {
    usercentertitle,
  },
  data(){
    return {
      name:'',//收货人
      PostCode:'',//邮编
      telphone:'',//固定电话
      optionsObj:{
        options:[
          {value:0,
          label:'省'},
        ],//选项卡---省
        value:0,//省
      },
      optionsObj1:{
        options:[
          {value:0,
          label:'市'},
        ],//选项卡---市
        value:0,//市
      },
      optionsObj2:{
        options:[
          {value:0,
          label:'区'},
        ],//选项卡---区
        value:0,//区
      },
      value2: '',//下拉3-地址
      addredetail:'',//详细地址
      options3: [{
        value: '0086',
        label: '+86'
      }],
      value3: '0086',//下拉4-手机区号
      phone:'',//手机号
      discernData:'',//识别文本域内容
      // discernData:'张敏，广东省深圳市宝安区塘尾地铁站标凡科技大厦6楼，13955454544',//识别文本域内容1
      setdfts:false,//是否默认地址
      errormsg:{
        name:'',
        addredetail:'',
        phone:'',
        PostCode:'',
        telphone:'',
      },//错误信息
      addressData:{
        name:'',//收货人
        select: { province: '', city: '', area: '' },//地址选项框选择值
        addressinfo:'',//详细地址
        phone:'',//手机号码
        code:'',//邮政编码
        phoneL:'',//座机号码前缀
        phoneR:'',//座机号码
        default:false,//是否设为默认地址---如果设为默认地址则会默认选择
        choice:false,//新增地址默认不选择---如果设为默认地址则会默认选择
        id:'',//用当前时间轴
        addresId:0,//省，
        addresId1:0,//市，
        addresId2:0,//区，
      },
      addOrEdit:0,//默认0新增，修改传入地址id
    }
  },
  props: {
    editAddres: Object,
  },
  methods:{
    ressetData(){
      // 重置表单
      this.setdfts=false
      this.telphone=''
      this.PostCode=''
      this.name=''
      this.addredetail=''
      this.phone=''
      this.addressData.addresId=0
      this.addressData.addresId1=0
      this.addressData.addresId2=0
      this.addOrEdit=0
      this.optionsObj.value =0
      this.optionsObj1.value =0
      this.optionsObj2.value =0
    },
    change(v,fn){
      // 地址栏选择--省
      this.$set(this,'optionsObj1',{
        options:[
          {value:0,
          label:'市'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      })
      this.$set(this,'optionsObj2',{
        options:[
          {value:0,
          label:'区'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      })
      this.addressData.addresId = v;
      if(v!=0){
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainCityList?ProvinceId='+v,this.keys())
          .then(res => {
              if(res.data.Success){
                var _data = [{value:0,
                    label:'市'}];
                for(let i in res.data.Data){
                  var _obj = {};
                  _obj.value = res.data.Data[i].CityID
                  _obj.label = res.data.Data[i].CityName
                  _data.push(_obj)
                }
                this.$set(this.optionsObj1,'options',_data)
                if(fn){
                  fn();
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        }})
      }
    },
    change1(v,fn){
      // 地址栏选择--市
      this.addressData.addresId1 = v
      this.$set(this,'optionsObj2',{
        options:[
          {value:0,
          label:'区'},
        ],//选项卡---配送方式
        value:0,//选项卡配送方式值
      })
      if(v!=0){
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainAreaList?ProvinceID='+this.addressData.addresId+'&CityId='+v,this.keys())
          .then(res => {
              if(res.data.Success){
                var _data = [{value:0,
                    label:'区'}];
                for(let i in res.data.Data){
                  var _obj = {};
                  _obj.value = res.data.Data[i].AreaID
                  _obj.label = res.data.Data[i].AreaName
                  _data.push(_obj)
                }
                this.$set(this.optionsObj2,'options',_data)
                if(fn){
                  fn();
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        }})
      }
    },
    change2(v,fn){
      // 地址栏选择--区
      this.addressData.addresId2 = v;
      if(fn){
        fn();
      }
    },
    saveinfo(){
      var flag = true;
      this.errormsg.name=''
      this.errormsg.addredetail = ''
      this.errormsg.phone = ''
      this.errormsg.PostCode = ''
      this.errormsg.telphone = ''
      // 保存信息
      var pattern = /^[0-9]{6}$/;
      if(this.PostCode&&!pattern.test(this.PostCode)){
        flag = false
        this.errormsg.PostCode='请填写正确邮编'
      }

      var pattern1 = /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
      if(this.telphone&&!pattern1.test(this.telphone)){
        flag = false
        this.errormsg.telphone='请填写正确固定电话'
      }

      if(!this.name){
        flag = false
        this.errormsg.name='请填写收货人'
      }
      if(this.name.length>25){
        flag = false
        this.errormsg.name='长度不能超过25个字符'
      }
      if(!this.optionsObj.value||!this.optionsObj1.value||!this.optionsObj2.value||!this.addredetail){
        flag = false
        this.errormsg.addredetail = '请填写完整地址信息'
      }
      if(!this.phone){
        flag = false
        this.errormsg.phone = '请填写联系手机'
      }
      var TEL_REGEXP = /^1[3456789]\d{9}$/;
      if(!TEL_REGEXP.test(this.phone)){
        flag = false
        this.errormsg.phone = '请填写正确的手机号码'
      }
      if(flag){
        // 验证成功请求保存
        this._axios({then:()=>{
          var _s='',_c='',_q='';
          for(let i in this.optionsObj.options){
            if(this.optionsObj.options[i].value==this.optionsObj.value){
              _s = this.optionsObj.options[i].label
            }
          }
          for(let i in this.optionsObj1.options){
            if(this.optionsObj1.options[i].value==this.optionsObj1.value){
              _c = this.optionsObj1.options[i].label
            }
          }
          for(let i in this.optionsObj2.options){
            if(this.optionsObj2.options[i].value==this.optionsObj2.value){
              _q = this.optionsObj2.options[i].label
            }
          }
          var _address = {};
          _address.defAddr = this.setdfts
          _address.telphone = this.telphone
          _address.PostCode = this.PostCode
          _address.ContactName = this.name
          _address.Address =_s+_c+_q+ this.addredetail
          _address.Mobile = this.phone
          _address.ProvinceID = this.addressData.addresId
          _address.CityID = this.addressData.addresId1
          _address.AreaID = this.addressData.addresId2
          _address.addrId = this.addOrEdit
          // _address.CustomerId = (this.$store.state.user&&this.$store.state.user.CustomerID?this.$store.state.user.CustomerID:'')
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/SaveAddress?ContactName='+_address.ContactName+'&Address='+_address.Address+'&Mobile='+_address.Mobile+'&ProvinceID='+_address.ProvinceID+'&CityID='+_address.CityID+'&AreaID='+_address.AreaID+'&PostCode='+_address.PostCode+'&telphone='+_address.telphone+'&defAddr='+_address.defAddr+'&addrId='+_address.addrId,this.keys({token:this._token({method:'get'})}))
            .then(res => {
              if(res.data.Success){
                // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
                //   this.$store.dispatch("cmtSetUser",null);//赋值
                //   this._usermet({method:'del'});//存本地
                //   this._token({method:'del'});//存本地
                //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
                // }
                if(this.addOrEdit==0){
                  this.$Message({
                      message: '保存成功!',
                      type: 'success',
                      duration:1500,
                      // offset:100
                  });
                }else{
                  this.$Message({
                      message: '修改成功!',
                      type: 'success',
                      duration:1500,
                      // offset:100
                  });
                }
                this.ressetData()
                this.$emit('exportResAdr')
                // if(this.addOrEdit==0){
                //   this.$emit('exportClose')
                //   this.$emit('exportData',res.data.Data)
                //   this.$Message({message:'新增地址成功！',type:'success',duration:1500})
                // }else{
                //   this.$emit('exportClose')
                //   this.$emit('exportData',res.data.Data)
                //   this.$Message({message:'新增地址成功！',type:'success',duration:1500})
                // }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
          },exp:true,catch:()=>{
            // 用户不存在
            // this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath}})
            var pms = this.$route.fullPath + '.html'
            this.$router.push({path:'/accountlogin',query:{redirect:pms}})
          }})
      }
    },
    setdft(){
      // 设置默认地址
      this.setdfts = !this.setdfts
    },
    discernBtn(){
      if(this.discernData==''){
        this.$MessageBox.alert('请按格式填写文本域',{type:'error'});
        return;
      }
      for(let i in this.addressDataErr){
        this.addressDataErr[i]=''
      }
      this._axios({then:()=>{
        this.axios.post('https://ctumin.51ctu.com:8018/logistics/adress_web?',{
          text: this.discernData,
        })
        .then(res => {
            this.$Message({
                message: '识别成功!',
                type: 'success',
                duration:1500,
                // offset:100
            });
            this.name=res.data.data[0].name
            this.phone=res.data.data[0].mobile
            this.addredetail=res.data.data[0].detail
            for(let i in this.optionsObj.options){
              if(this.optionsObj.options[i].label==res.data.data[0].province_name){
                this.optionsObj.value = this.optionsObj.options[i].value
                this.change(this.optionsObj.value,()=>{
                  for(let i in this.optionsObj1.options){
                    if(this.optionsObj1.options[i].label==res.data.data[0].city_name){
                      this.optionsObj1.value = this.optionsObj1.options[i].value
                      this.change1(this.optionsObj1.value,()=>{
                        for(let i in this.optionsObj2.options){
                          if(this.optionsObj2.options[i].label==res.data.data[0].county_name){
                            this.optionsObj2.value = this.optionsObj2.options[i].value
                            this.change2(this.optionsObj2.value)
                          }else if(this.optionsObj2.options[i].label==res.data.data[0].county_shortname){
                             this.optionsObj2.value = this.optionsObj2.options[i].value
                             this.change2(this.optionsObj2.value)
                          }
                        }
                      })
                    }else if(this.optionsObj1.options[i].label==res.data.data[0].city_shortname){
                       this.optionsObj1.value = this.optionsObj1.options[i].value
                       this.change1(this.optionsObj1.value,()=>{
                          for(let i in this.optionsObj2.options){
                            if(this.optionsObj2.options[i].label==res.data.data[0].county_name){
                              this.optionsObj2.value = this.optionsObj2.options[i].value
                              this.change2(this.optionsObj2.value)
                            }else if(this.optionsObj2.options[i].label==res.data.data[0].county_shortname){
                               this.optionsObj2.value = this.optionsObj2.options[i].value
                               this.change2(this.optionsObj2.value)
                            }
                          }
                       })
                    }
                  }
                })
              }else if(this.optionsObj.options[i].label==res.data.data[0].province_shortname){
                 this.optionsObj.value = this.optionsObj.options[i].value
                 this.change(this.optionsObj.value,()=>{
                    for(let i in this.optionsObj1.options){
                      if(this.optionsObj1.options[i].label==res.data.data[0].city_name){
                        this.optionsObj1.value = this.optionsObj1.options[i].value
                        this.change1(this.optionsObj1.value,()=>{
                          for(let i in this.optionsObj2.options){
                            if(this.optionsObj2.options[i].label==res.data.data[0].county_name){
                              this.optionsObj2.value = this.optionsObj2.options[i].value
                              this.change2(this.optionsObj2.value)
                            }else if(this.optionsObj2.options[i].label==res.data.data[0].county_shortname){
                               this.optionsObj2.value = this.optionsObj2.options[i].value
                               this.change2(this.optionsObj2.value)
                            }
                          }
                        })
                      }else if(this.optionsObj1.options[i].label==res.data.data[0].city_shortname){
                         this.optionsObj1.value = this.optionsObj1.options[i].value
                         this.change1(this.optionsObj1.value,()=>{
                            for(let i in this.optionsObj2.options){
                              if(this.optionsObj2.options[i].label==res.data.data[0].county_name){
                                this.optionsObj2.value = this.optionsObj2.options[i].value
                                this.change2(this.optionsObj2.value)
                              }else if(this.optionsObj2.options[i].label==res.data.data[0].county_shortname){
                                 this.optionsObj2.value = this.optionsObj2.options[i].value
                                 this.change2(this.optionsObj2.value)
                              }
                            }
                         })
                      }
                    }
                 })
              }
            }

          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    },
  },
  mounted(){
    // 初始化地址
    this._axios({then:()=>{
      this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainProvinceList',this.keys())
      .then(res => {
          if(res.data.Success){
            var _data = [{value:0,
                label:'省'}];
            for(let i in res.data.Data){
              var _obj = {};
              _obj.value = res.data.Data[i].ProvinceID
              _obj.label = res.data.Data[i].ProvinceName
              _data.push(_obj)
            }
            this.$set(this.optionsObj,'options',_data)
            // for(let i in this.optionsObj.options){
            //   if(this.optionsObj.options[i].value==this.initData.addresId){
            //     this.optionsObj.value = this.optionsObj.options[i].value
            //     this.change(this.optionsObj.value,()=>{
            //       for(let i in this.optionsObj1.options){
            //         if(this.optionsObj1.options[i].value==this.initData.addresId1){
            //           this.optionsObj1.value = this.optionsObj1.options[i].value
            //           this.change1(this.optionsObj1.value,()=>{
            //             for(let i in this.optionsObj2.options){
            //               if(this.optionsObj2.options[i].value==this.initData.addresId2){
            //                 this.optionsObj2.value = this.optionsObj2.options[i].value
            //                 this.change2(this.optionsObj2.value)
            //               }
            //             }
            //           })
            //         }
            //       }
            //     })
            //   }
            // }
          }else{
            this.$MessageBox.alert(res.data.Error,{type:'error'});
          }
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
    }})
  },
  watch:{
    editAddres(n,o){
      // 修改地址
      // console.log(n)
      this.setdfts=n.IsDefault===1?true:false
      this.telphone=n.Telephone?n.Telephone:''
      this.PostCode=n.PostCode?n.PostCode:''
      this.name=n.ContactName
      this.addredetail=n.splitAddress
      this.phone=n.Mobile
      for(let i in this.optionsObj.options){
        if(this.optionsObj.options[i].value==n.ProvinceID){
          this.optionsObj.value = this.optionsObj.options[i].value
          this.change(this.optionsObj.value,()=>{
            for(let i in this.optionsObj1.options){
              if(this.optionsObj1.options[i].value==n.CityID){
                this.optionsObj1.value = this.optionsObj1.options[i].value
                this.change1(this.optionsObj1.value,()=>{
                  for(let i in this.optionsObj2.options){
                    if(this.optionsObj2.options[i].value==n.AreaID){
                      this.optionsObj2.value = this.optionsObj2.options[i].value
                      this.change2(this.optionsObj2.value)
                    }
                  }
                })
              }
            }
          })
        }
      }
      this.addOrEdit=n.AddressID
    },
    name(n,o){
      // 移除提示
      this.errormsg.name=''
    },
    addredetail(n,o){
      // 移除提示
      this.errormsg.addredetail=''
    },
    phone(n,o){
      // 移除提示
      this.errormsg.phone=''
    },
    'addressData.addresId'(n,o){
      // 移除提示
      this.errormsg.addredetail=''
    },
    'addressData.addresId1'(n,o){
      // 移除提示
      this.errormsg.addredetail=''
    },
    'addressData.addresId2'(n,o){
      // 移除提示
      this.errormsg.addredetail=''
    }
  }
}
</script>
<style  lang="scss" scoped>
.addressdataset {
  width: 1000px;float: right;background: #fff;margin-top:-10px;padding-left:10px;
  .usercentertitle {
    margin-bottom:30px;margin-top:20px;
  }
  .group-adr {
    display: flex;justify-content: flex-start;margin-bottom:40px;
    .lt {
      width: 90px;text-align: right;
      .xx {
        color:#f54e62;vertical-align: -2px;
      }
    }
    .rt {
      position: relative;
      .ipt {
        margin-top:-10px;
        input {
          width: 308px;height: 40px;border:1px solid #dddcdd;border-radius: 5px;padding-left:10px;
          &::placeholder {
            font-size:12px;color:#c9cac9;
          }
        }
      }
      .slt {
        margin-top:-10px;margin-bottom:10px;
        .el-select  {
          width: 103px;margin-right: 6px;
        }

      }
      .area {
        textarea {
          width: 298px;padding-left:10px;border:1px solid #dddcdd;border-radius: 5px;padding:10px 10px;
          &::placeholder {
            font-size: 12px;color:#c9cac9;
          }
        }
      }
      .ipts {
        .el-select {
          height: 42px;width: 80px;
          /deep/ input {
            height: 42px;border-right: 0px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;
          }
          /deep/.is-focus {
            input {
              border-color:#dddcdd;
            }
          }
          /deep/.el-input__inner:focus {
            border-color:#dddcdd;
          }
        }
        .txt {
          border-top-left-radius: 0px;border-bottom-left-radius: 0px;border-left:0px;width: 230px;
        }
      }
      .err {
        position: absolute;color:#f54e62;font-size: 12px;left:0px;top:33px;
      }
      .errs {
        top:95px;
      }
    }
  }
  .discern {
    margin-top:-10px;float:left;margin-left:20px;
    textarea {
      font-size:14px;color:#333;line-height: 16px;
      background: #f2f2f2;width: 370px;height: 50px;border-radius: 5px; margin:0 auto;display: block;padding:10px;
      &::placeholder {
        font-size:12px;
      }
    }
    button {
      background: none;margin:0 auto;display: block;display: flex;justify-content: flex-start;color:#197ba0;margin-top:5px;
      span {
        border:1px solid #197ba0;width: 12px;height: 12px;display: block;margin-top:3px;border-radius: 50%;margin-right: 4px;position: relative;
        &:after {
          content: '';display: block;width: 6px;height: 6px;background: #197ba0;position: absolute;top:3px;left:3px;border-radius: 50%;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .dftadr {
    margin-top:150px;padding-left:20px;font-size: 14px;cursor: pointer;
    span {
      display: inline-block;width: 15px;height: 15px;border:1px solid #bfc0c0;border-radius: 3px;margin-right: 10px;text-align: center;line-height: 15px;vertical-align: -3px;position: relative;
      svg {
        width: 12px;height: 12px;fill: #f54e62;position: absolute;left:2px;top:2px;
      }
    }
  }
  .savebtn {
    width: 390px;height: 100px;display: flex;justify-content: center;align-items: center;margin-left:20px;
    button {
      width: 76px;height: 30px;border-radius: 5px;background: #f44e56;color:#fff;line-height: 28px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
