<!-- 个人中心 地址列表-->
<template>
  <div class="addressdatalist">
    <div class="head">
      <usercentertitle class='usercentertitle' :types='2' title='收货人信息'></usercentertitle>
      <p>您已保存{{addressList.length}}条收货地址</p>
    </div>
    <div class='list' v-for='v,i in addressList' :key='i'>
      <div class="info">
        <div class="d">{{v.ContactName}}</div>
        <div class="d">{{v.Address}}</div>
        <div class="d">{{v.Mobile}}</div>
        <div class="d">{{v.Telephone?v.Telephone:''}}</div>
      </div>
      <div class="menu">
        <button :class="{default:v.IsDefault===1}" @click='setdft(v,i)'>{{v.IsDefault===1?'默认地址':'设为默认'}}</button>
        <button title="修改地址"   @click='changeAddress(v)'><svg><use xlink:href="#iconbianji"></use></svg></button>
        <button title="删除地址" @click='deladdress(v,i)'><svg><use xlink:href="#iconshanchu1"></use></svg></button>
      </div>
    </div>
  </div>
</template>
<script>
import usercentertitle from '@/components/usercenter-title/usercenter-title.vue';//内容标题
export default {
  name: 'addressdatalist',
  components: {
    usercentertitle,
  },
  data(){
    return {
    }
  },
  props: {
    addressList: Array
  },
  methods:{
    changeAddress(v){
      // 修改地址
      this.$emit('exportChange',v)
      window.scrollTo(0,0);
    },
    setdft(v,index){
      // 设为默认
      this._axios({then:()=>{
      // 获取地址列表
      this.axios.post(process.env.VUE_APP_URL+'UserCenter/SetDefaultAddress?AddressId='+v.AddressID,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
              //   this.$store.dispatch("cmtSetUser",null);//赋值
              //   this._usermet({method:'del'});//存本地
              //   this._token({method:'del'});//存本地
              //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
              // }
              this.$emit('exportResAdr')
              // for(let i in this.addressList){
              //   this.addressList[i].IsDefault=0
              // }
              // this.addressList[index].IsDefault=1
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
          var pms = this.$route.fullPath + '.html'
          this.$router.push({path:'/accountlogin',query:{redirect:pms}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    deladdress(v,index){
      this.$MessageBox.confirm('确认要删除该条地址吗？','删除地址', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'error',
        customClass:'joinCar'
      }).then(() => {
        // console.log('确认')
        this._axios({then:()=>{
        // 删除收货地址
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/delAddress?addrId='+v.AddressID,this.keys())
          .then(res => {
              if(res.data.Success){
                this.addressList.splice(index,1)
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        },exp:true,catch:()=>{
            // this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
            var pms = this.$route.fullPath + '.html'
            this.$router.push({path:'/accountlogin',query:{redirect:pms}})
        }})
      }).catch(() => {
        // console.log('取消')
      });
    }
  },
  mounted(){
  }
}
</script>
<style lang="scss">
.joinCar {
  .el-message-box__btns {
    button {
      &:hover {
        color:#333;background: rgba(0,0,0,0.08);border:1px solid #ccc;
      }
    }
    .el-button--primary {
      background: #f54d70;border:1px solid #f54d70;
      &:hover {
        color:#fff;background: #f54d70;opacity: 0.8;border:1px solid #f54d70;
      }
    }
  }
}
</style>
<style  lang="scss" scoped>
.addressdatalist {
  width: 1000px;float:right;padding-left:10px;background: #fff;padding-bottom:40px;
  .head {
    position: relative;
    p {
      position: absolute;top:20px;right:50px;font-size: 14px;
    }
  }
  .list {
    display: flex;justify-content: space-between;width: 960px;margin:0 auto;border-bottom:1px solid #f2f2f2;margin:0 auto;height: 70px;
    .info {
      display: flex;justify-content: flex-start;height: 70px;align-items: center;
      .d {
        margin-left: 30px;font-size: 14px;font-size: 14px;color:#333;
      }
    }
    .menu {
      display: flex;align-items: center;
      button {
        background: none;font-size: 14px;
        svg {
          fill: #7c7d7e;width: 20px;height: 20px;
        }
        &:nth-child(n+2) {
          margin-right: 20px;
        }
        &:nth-child(1) {
          margin-right: 90px;padding:5px 10px;border-radius: 5px;
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .default {
        background: #f44e56;color:#fff;
      }
    }
    &:last-child {
      border-bottom:0px;
    }
  }
}
</style>
