<!-- 个人中心---收货地址 -->
<template>
  <div class="addressdata">
    <usercenterhr class='usercenterhr'></usercenterhr>
    <usercenterlt  class='usercenterlt' :nav='6'></usercenterlt>
    <addressdataset class='addressdataset' :editAddres='editAddres' @exportResAdr='exportResAdr'></addressdataset>
    <addressdatalist v-if='addressList&&addressList.length>0' :addressList='addressList' @exportChange='exportChange' @exportResAdr='exportResAdr'></addressdatalist>
  </div>
</template>
<script>
import usercenterhr from '@/components/usercenter-hr/usercenter-hr.vue';//顶部hr
import usercenterlt from '@/components/usercenter-lt/usercenter-lt.vue';//左侧栏目
import addressdataset from '@/components/addressdataset/addressdataset.vue';//设置地址
import addressdatalist from '@/components/addressdatalist/addressdatalist.vue';//地址列表
export default {
  name: 'addressdata',
  components: {
    usercenterhr,
    usercenterlt,
    addressdataset,
    addressdatalist
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'收货地址-微微定',
      editAddres:{},
      addressList:[],//地址列表
    }
  },
  props: {
    msg: String
  },
  methods:{
    exportChange(v){
      // 修改地址
      this.editAddres = v
    },
    exportResAdr(){
      // 保存更新地址
      this.getAddres()
    },
    getAddres(){
      // 获取地址列表
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainAddressList',this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.addressList = res.data.Data.List
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.getAddres()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.addressdata {
  width: 1200px;margin:0 auto;zoom:1;padding-bottom:20px;
  .usercenterhr {
    width: 1200px;margin:0 auto;margin-top:20px;margin-bottom:10px;position: relative;z-index: 9;
  }
  .usercenterlt {
    float:left;width: 180px;margin-top:-10px;
  }
  .addressdataset {
    padding-bottom:30px;
  }
  &:after {
    content: "";display: block;clear:both;
  }
}
</style>
